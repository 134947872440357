import axios, { type AxiosError } from 'axios'
import { type ReactNode } from 'react'
import { type FieldErrors } from 'react-hook-form'
import * as Sentry from '@sentry/browser'
import { sendErrorEvent, sendHotjarEvent } from './events'

export const handleFormErrors = (
    error: Error | AxiosError,
    setError: any,
    handleRetry: (attempt: number) => void,
    attempt: number = 1,
    onComplete: () => void,
    isLoginPage: string | boolean = false
): void => {
    const attemptRetry = (type: string, error: string = '{{GENERIC}}'): void => {
        if (attempt === 1) {
            handleRetry(attempt + 1)
        } else {
            handleSetError('root.error', type, error)
        }
    }

    const handleSetError = (index: string, type: string, message: ReactNode): void => {
        setError(index, { type, message })
        onComplete()
    }

    if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
            sendHotjarEvent('received_422_error')
            if (error.response?.data?.errors !== null && error.response?.data?.errors !== undefined) {
                // Set validation errors
                Object.keys(error.response?.data?.errors).forEach((index: any) => {
                    const errors = error.response?.data?.errors[index]
                    handleSetError(index, 'custom', errors[0])
                })
            }
            if (error.response?.data?.message !== null) {
                handleSetError(
                    'root.error',
                    '422',
                    `${error.response?.data?.message} ${error.response?.data?.errors !== null && typeof error.response?.data?.errors === 'string' ? `- ${error.response?.data?.errors}` : ''}`
                )
            } else {
                logError(error, { message: 'Axios error when submitting form - error.response?.data?.message = null', status: error.response?.status })
                attemptRetry('422')
            }
        } else if (error.response?.status === 401) {
            if (isLoginPage) {
                handleSetError('root.error', '401', typeof isLoginPage === 'string' ? isLoginPage : 'Hmmm, the email address or password is incorrect.')
            } else {
                handleSetError('root.error', '401', "Oops, it seems you're unauthorised to access this data.")
                logError(error, { message: '401 unauthorised error when submitting non-login form', status: error.response?.status })
            }
        } else {
            sendHotjarEvent('received_unknown_error')
            logError(error, { message: 'Axios error when submitting form', status: error.response?.status })
            attemptRetry(`${error.response?.status}`)
        }
    } else {
        sendHotjarEvent('received_unknown_error')
        logError(error, { message: 'Axios error when submitting form' })
        attemptRetry('unknown')
    }
}

export const handleRetry = async (error: Error | AxiosError, attempt: number = 1, onRetry: (attempt: number) => void): Promise<any> => {
    return await new Promise((resolve, reject) => {
        if ((axios.isAxiosError(error) && error.response?.status !== 422) || !axios.isAxiosError(error)) {
            if (attempt === 1) {
                onRetry(attempt)
            } else {
                reject(error)
            }
        } else {
            reject(error)
        }
    })
}

export const hasRootError = (errors: FieldErrors): boolean => {
    return errors.root?.error !== undefined
}

export const logError = (error: Error | AxiosError, context: object | null = null, doSendErrorEvent = true): void => {
    if (doSendErrorEvent) {
        void sendErrorEvent({ error: error.message ?? 'Unknown error', ...(context ?? {}) })
    }
    if (process.env.NODE_ENV !== 'development') {
        if (context != null) {
            Sentry.setContext('extra', context)
        }

        Sentry.captureEvent(error)
    }
}

export const logMessage = (message: string, context: object | null = null): void => {
    void sendErrorEvent({ message, ...(context ?? {}) })
    if (process.env.NODE_ENV !== 'development') {
        if (context != null) {
            Sentry.setContext('extra', context)
        }

        Sentry.captureMessage(message)
    }
}
