exports.components = {
  "component---src-content-aussie-pages-coverage-tsx": () => import("./../../../src/content/aussiePages/coverage.tsx" /* webpackChunkName: "component---src-content-aussie-pages-coverage-tsx" */),
  "component---src-content-aussie-pages-mobile-phone-plans-tsx": () => import("./../../../src/content/aussiePages/mobile-phone-plans.tsx" /* webpackChunkName: "component---src-content-aussie-pages-mobile-phone-plans-tsx" */),
  "component---src-content-aussie-pages-our-network-tsx": () => import("./../../../src/content/aussiePages/our-network.tsx" /* webpackChunkName: "component---src-content-aussie-pages-our-network-tsx" */),
  "component---src-content-aussie-pages-partners-tsx": () => import("./../../../src/content/aussiePages/partners.tsx" /* webpackChunkName: "component---src-content-aussie-pages-partners-tsx" */),
  "component---src-content-aussie-pages-roaming-tsx": () => import("./../../../src/content/aussiePages/roaming.tsx" /* webpackChunkName: "component---src-content-aussie-pages-roaming-tsx" */),
  "component---src-content-aussie-pages-squad-savings-tsx": () => import("./../../../src/content/aussiePages/squad-savings.tsx" /* webpackChunkName: "component---src-content-aussie-pages-squad-savings-tsx" */),
  "component---src-content-landing-pages-5-g-mobile-plans-tsx": () => import("./../../../src/content/landingPages/5g-mobile-plans.tsx" /* webpackChunkName: "component---src-content-landing-pages-5-g-mobile-plans-tsx" */),
  "component---src-content-landing-pages-prepaid-mobile-plans-tsx": () => import("./../../../src/content/landingPages/prepaid-mobile-plans.tsx" /* webpackChunkName: "component---src-content-landing-pages-prepaid-mobile-plans-tsx" */),
  "component---src-content-landing-pages-unlimited-data-mobile-plans-tsx": () => import("./../../../src/content/landingPages/unlimited-data-mobile-plans.tsx" /* webpackChunkName: "component---src-content-landing-pages-unlimited-data-mobile-plans-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cancellation-feedback-tsx": () => import("./../../../src/pages/cancellation-feedback.tsx" /* webpackChunkName: "component---src-pages-cancellation-feedback-tsx" */),
  "component---src-pages-complaints-tsx": () => import("./../../../src/pages/complaints.tsx" /* webpackChunkName: "component---src-pages-complaints-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-delete-account-tsx": () => import("./../../../src/pages/delete-account.tsx" /* webpackChunkName: "component---src-pages-delete-account-tsx" */),
  "component---src-pages-es-monthly-esim-plans-tsx": () => import("./../../../src/pages/es/monthly-esim-plans.tsx" /* webpackChunkName: "component---src-pages-es-monthly-esim-plans-tsx" */),
  "component---src-pages-esim-compatibility-checker-tsx": () => import("./../../../src/pages/esim-compatibility-checker.tsx" /* webpackChunkName: "component---src-pages-esim-compatibility-checker-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-complete-tsx": () => import("./../../../src/pages/order/complete.tsx" /* webpackChunkName: "component---src-pages-order-complete-tsx" */),
  "component---src-pages-order-details-tsx": () => import("./../../../src/pages/order/details.tsx" /* webpackChunkName: "component---src-pages-order-details-tsx" */),
  "component---src-pages-order-load-tsx": () => import("./../../../src/pages/order/load.tsx" /* webpackChunkName: "component---src-pages-order-load-tsx" */),
  "component---src-pages-order-payment-tsx": () => import("./../../../src/pages/order/payment.tsx" /* webpackChunkName: "component---src-pages-order-payment-tsx" */),
  "component---src-pages-order-processing-tsx": () => import("./../../../src/pages/order/processing.tsx" /* webpackChunkName: "component---src-pages-order-processing-tsx" */),
  "component---src-pages-order-service-tsx": () => import("./../../../src/pages/order/service.tsx" /* webpackChunkName: "component---src-pages-order-service-tsx" */),
  "component---src-pages-pt-monthly-esim-plans-tsx": () => import("./../../../src/pages/pt/monthly-esim-plans.tsx" /* webpackChunkName: "component---src-pages-pt-monthly-esim-plans-tsx" */),
  "component---src-pages-refer-[code]-tsx": () => import("./../../../src/pages/refer/[code].tsx" /* webpackChunkName: "component---src-pages-refer-[code]-tsx" */),
  "component---src-pages-refer-tsx": () => import("./../../../src/pages/refer.tsx" /* webpackChunkName: "component---src-pages-refer-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-set-password-tsx": () => import("./../../../src/pages/set-password.tsx" /* webpackChunkName: "component---src-pages-set-password-tsx" */),
  "component---src-pages-sustainability-tsx": () => import("./../../../src/pages/sustainability.tsx" /* webpackChunkName: "component---src-pages-sustainability-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-travel-esims-search-tsx": () => import("./../../../src/pages/travel-esims/search.tsx" /* webpackChunkName: "component---src-pages-travel-esims-search-tsx" */),
  "component---src-pages-travel-esims-tsx": () => import("./../../../src/pages/travel-esims.tsx" /* webpackChunkName: "component---src-pages-travel-esims-tsx" */),
  "component---src-pages-vi-monthly-esim-plans-tsx": () => import("./../../../src/pages/vi/monthly-esim-plans.tsx" /* webpackChunkName: "component---src-pages-vi-monthly-esim-plans-tsx" */),
  "component---src-pages-wtf-is-esim-tsx": () => import("./../../../src/pages/wtf-is-esim.tsx" /* webpackChunkName: "component---src-pages-wtf-is-esim-tsx" */),
  "component---src-pages-zensim-app-tsx": () => import("./../../../src/pages/zensim-app.tsx" /* webpackChunkName: "component---src-pages-zensim-app-tsx" */),
  "component---src-pages-zh-monthly-esim-plans-tsx": () => import("./../../../src/pages/zh/monthly-esim-plans.tsx" /* webpackChunkName: "component---src-pages-zh-monthly-esim-plans-tsx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-best-data-only-mobile-plans-in-australia-for-2024-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/Best-Data-Only-Mobile-Plans-in-Australia-for-2024.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-best-data-only-mobile-plans-in-australia-for-2024-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-brisbane-airport-your-complete-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/buying-an-esim-in-brisbane-airport-your-complete-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-brisbane-airport-your-complete-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-gold-coast-airport-your-complete-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/buying-an-esim-in-gold-coast-airport-your-complete-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-gold-coast-airport-your-complete-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-melbourne-airport-your-complete-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/buying-an-esim-in-melbourne-airport-your-complete-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-melbourne-airport-your-complete-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-perth-airport-your-complete-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/buying-an-esim-in-perth-airport-your-complete-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-perth-airport-your-complete-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-sydney-airport-your-complete-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/buying-an-esim-in-sydney-airport-your-complete-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-buying-an-esim-in-sydney-airport-your-complete-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-complete-guide-to-esim-compatible-phones-australia-2024-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/complete-guide-to-esim-compatible-phones-australia-2024.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-complete-guide-to-esim-compatible-phones-australia-2024-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-comprehensive-guide-to-mobile-networks-in-australia-2024-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/Comprehensive-Guide-to-Mobile-Networks-in-Australia-2024.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-comprehensive-guide-to-mobile-networks-in-australia-2024-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-esim-installation-guide-how-to-install-a-travel-esim-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/esim-installation-guide-how-to-install-a-travel-esim.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-esim-installation-guide-how-to-install-a-travel-esim-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-esim-troubleshoorting-guide-how-to-fix-esim-connection-issues-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/esim-troubleshoorting-guide-how-to-fix-esim-connection-issues.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-esim-troubleshoorting-guide-how-to-fix-esim-connection-issues-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-everything-you-need-to-know-about-family-mobile-plans-in-2024-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/Everything-You-Need-to-Know-About-Family-Mobile-Plans-in-2024.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-everything-you-need-to-know-about-family-mobile-plans-in-2024-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-call-new-zealand-from-australia-a-comprehensive-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/How-to-Call-New-Zealand-from-Australia-A-Comprehensive-Guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-call-new-zealand-from-australia-a-comprehensive-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-call-overseas-from-australia-a-complete-guide-for-2024-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/how-to-call-overseas-from-australia-a-complete-guide-for-2024.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-call-overseas-from-australia-a-complete-guide-for-2024-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-call-the-uk-from-australia-a-comprehensive-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/how-to-call-the-uk-from-australia-a-comprehensive-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-call-the-uk-from-australia-a-comprehensive-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-call-usa-from-australia-complete-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/how-to-call-usa-from-australia-complete-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-call-usa-from-australia-complete-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-transfer-e-sim-to-a-new-phone-australia-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/How-to-Transfer-eSIM-to-a-New-Phone-Australia.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-how-to-transfer-e-sim-to-a-new-phone-australia-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-step-by-step-guide-how-to-set-up-mobile-service-on-your-iphone-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/step-by-step-guide-how-to-set-up-mobile-service-on-your-iphone.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-step-by-step-guide-how-to-set-up-mobile-service-on-your-iphone-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-the-ultimate-guide-to-understanding-prepaid-mobile-plans-2024-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/The-Ultimate-Guide-to-Understanding-Prepaid-Mobile-Plans-2024.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-the-ultimate-guide-to-understanding-prepaid-mobile-plans-2024-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-top-prepaid-unlimited-data-mobile-plans-in-australia-for-2024-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/Top-Prepaid-Unlimited-Data-Mobile-Plans-in-Australia-for-2024.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-top-prepaid-unlimited-data-mobile-plans-in-australia-for-2024-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-understanding-e-sim-on-i-phone-key-facts-and-compatibility-in-2024-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/Understanding-eSIM-on-iPhone-Key-Facts-and-Compatibility-in-2024.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-understanding-e-sim-on-i-phone-key-facts-and-compatibility-in-2024-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-unlocking-the-potential-of-esim-for-smart-watches-in-australia-2024-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/unlocking-the-potential-of-esim-for-smart-watches-in-australia-2024-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-unlocking-the-potential-of-esim-for-smart-watches-in-australia-2024-guide-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-src-content-posts-which-samsung-phones-are-compatible-with-5-g-the-ultimate-guide-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/opt/build/repo/src/content/posts/which-samsung-phones-are-compatible-with-5g-the-ultimate-guide.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-src-content-posts-which-samsung-phones-are-compatible-with-5-g-the-ultimate-guide-mdx" */),
  "component---src-templates-content-tsx-content-file-path-src-content-content-pages-privacy-mdx": () => import("./../../../src/templates/content.tsx?__contentFilePath=/opt/build/repo/src/content/contentPages/privacy.mdx" /* webpackChunkName: "component---src-templates-content-tsx-content-file-path-src-content-content-pages-privacy-mdx" */),
  "component---src-templates-country-tsx": () => import("./../../../src/templates/country.tsx" /* webpackChunkName: "component---src-templates-country-tsx" */),
  "component---src-templates-plan-tsx": () => import("./../../../src/templates/plan.tsx" /* webpackChunkName: "component---src-templates-plan-tsx" */),
  "component---src-templates-region-tsx": () => import("./../../../src/templates/region.tsx" /* webpackChunkName: "component---src-templates-region-tsx" */),
  "component---src-templates-travel-plan-tsx": () => import("./../../../src/templates/travel-plan.tsx" /* webpackChunkName: "component---src-templates-travel-plan-tsx" */)
}

